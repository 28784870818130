import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { messages } from 'config/messages';

export const kegCorrectionInitialState = {
  isLoading: false,
  state: 'done',
  currentLineId: null,
};

export const kegCorrectionModel = types
  .model({
    isLoading: types.boolean,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    currentLineId: types.maybeNull(types.integer),
  })
  .actions(self => ({
    setCurrentLine(item_id) {
      const root = getRootStore();
      const itemLine = root.itemLinesStore.getItemLineByItemId(item_id);
      if (itemLine) {
        self.currentLineId = itemLine.line_id;
      }
    },

    fixItemItemLines: flow(function* (body) {
      try {
        self.isLoading = true;
        self.state = 'pending';
        const response = yield api.fixItemItemLines(body);
        self.isLoading = false;
        self.state = 'done';
        return response;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    applyItemLineFixes: flow(function* (body) {
      try {
        self.isLoading = true;
        self.state = 'pending';
        const response = yield api.applyItemLineFixes(body);
        if (Array.isArray(response?.data?.result?.fixed) && response.data.result.fixed.length > 0) {
          const root = getRootStore();
          response.data.result.fixed.forEach(fixed => {
            const { _item, _item_line, _item_events_summary } = fixed;

            if (_item_line) {
              const { deletedQueued, closedCurrent, updatedCurrent, added } = _item_line;
              deletedQueued &&
                Array.isArray(deletedQueued) &&
                deletedQueued.forEach(root.itemLinesStore.removeItemLine);
              closedCurrent &&
                Array.isArray(closedCurrent) &&
                closedCurrent.forEach(root.itemLinesStore.updateOrInsertItemLine);
              updatedCurrent &&
                Array.isArray(updatedCurrent) &&
                updatedCurrent.forEach(root.itemLinesStore.updateOrInsertItemLine);
              added &&
                Array.isArray(added) &&
                added.forEach(root.itemLinesStore.updateOrInsertItemLine);
            }
            root.itemsStore.updateItem({ ..._item, _item_events_summary });
          });
        } else {
          return Promise.reject(new Error(messages.change.noResponse.KEG_CORRECTION));
        }
        self.isLoading = false;
        self.state = 'done';
        return response;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    itemLinesCorrect: flow(function* (body) {
      try {
        self.isLoading = true;
        self.state = 'pending';
        const response = yield api.itemLinesCorrect(body);
        self.isLoading = false;
        self.state = 'done';
        return response;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    disconnectItem: flow(function* (item_id, status) {
      try {
        self.isLoading = true;
        self.state = 'pending';
        const root = getRootStore();
        const response = yield api.disconnectCurrentItem({
          line_id: self.currentLineId,
          item_status_code: status,
        });

        if (response?.data?.result?.disconnected?.length) {
          const { _lines, disconnected } = response.data.result;

          if (Array.isArray(disconnected) && disconnected?.length) {
            disconnected.forEach(d => {
              const { _item_line, _item, _item_events_summary } = d;

              root.itemLinesStore.updateItemLine(_item_line);
              root.itemsStore.updateItem({ ..._item, _item_events_summary });
            });
          }

          Array.isArray(_lines) && _lines?.length && _lines.forEach(root.linesStore.updateLine);
        } else {
          return Promise.reject(new Error(messages.change.noResponse.KEG_CORRECTION));
        }
        self.isLoading = false;
        self.state = 'done';
        return response;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    connectNewItem: flow(function* (item_id) {
      try {
        self.isLoading = true;
        self.state = 'pending';
        const root = getRootStore();
        const connectedResponse = yield api.connectNewItem({
          line_id: self.currentLineId,
          item_id: item_id,
        });
        if (connectedResponse?.data?.result?.connected?.length) {
          const { _lines, connected } = connectedResponse.data.result;

          if (Array.isArray(connected) && connected?.length) {
            connected.forEach(c => {
              const { _item_line, _item, _item_events_summary } = c;

              root.itemLinesStore.updateItemLine(_item_line);
              root.itemsStore.updateItem({ ..._item, _item_events_summary });
            });
          }

          Array.isArray(_lines) && _lines?.length && _lines.forEach(root.linesStore.updateLine);
        }

        self.isLoading = false;
        self.state = 'done';
        return connectedResponse;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    updateItem: flow(function* (item_id, body) {
      try {
        const root = getRootStore();
        self.isLoading = true;
        self.state = 'pending';
        const response = yield api.updateItem(item_id, body);
        if (response && response.data && response.data.row) {
          root.itemsStore.updateOrInsertItem(response.data.row);
        }
        self.isLoading = false;
        self.state = 'done';
        return response;
      } catch (err) {
        self.isLoading = false;
        self.state = 'error';
        return Promise.reject(err);
      }
    }),
    getItemLineByItemId(item_id) {
      const root = getRootStore();
      return root.itemLinesStore.getItemLineByItemId(item_id);
    },
    swapItemOnTheLine: flow(function* (body) {
      const root = getRootStore();
      try {
        const response = yield api.swapItemOnTheLine(body);
        if (
          response &&
          response.data &&
          response.data.result &&
          response.data.result._items &&
          response.data.result._items.length &&
          response.data.result._item_lines_updated &&
          response.data.result._item_lines_deleted
        ) {
          response.data.result._items.forEach(_item => {
            if (_item && _item.id) {
              root.itemsStore.updateOrInsertItem(_item);
            }
          });
          response.data.result._item_lines_updated.forEach(_item_line => {
            if (_item_line && _item_line.id) {
              root.itemLinesStore.updateOrInsertItemLine(_item_line);
            }
          });
          response.data.result._item_lines_deleted.forEach(_item_line => {
            if (_item_line && _item_line.id) {
              root.itemLinesStore.removeItemLine(_item_line);
            }
          });
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
  }));
